import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid2 as Grid,
  Typography,
  useTheme
} from '@mui/material'
import ExperienceData from './experience'
import Link from '../../components/Link'
import MotionBox from '../../components/Motion/MotionBox'

const Experience = () => {
  const theme = useTheme()

  return (
    <Grid
      container
      mt={10}
      mx='auto'
      spacing={5}
      px={{ xs: 5, sm: 10, md: 20 }}>
      {ExperienceData.map((ex, idx) => (
        <Grid size={{ xs: 12, sm: 6 }} key={`experience_${idx}`}>
          <MotionBox
            height='100%'
            initial={{ x: idx % 2 ? '-100vw' : '200vw', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: idx / 10 }}>
            <Card sx={{ height: '100%' }} elevation={0}>
              <CardMedia
                sx={{ height: 140 }}
                image={ex.image}
                title='green iguana'
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant='h5'
                  component='div'
                  color={theme.palette.getContrastText(
                    theme.palette.background.paper
                  )}>
                  {ex.title}
                </Typography>
                <Typography
                  variant='body2'
                  color={theme.palette.getContrastText(
                    theme.palette.background.paper
                  )}>
                  {ex.desc}
                </Typography>
              </CardContent>
              <CardActions>
                <Link to={ex.link} target='_blank'>
                  {ex.link}
                </Link>
              </CardActions>
            </Card>
          </MotionBox>
        </Grid>
      ))}
    </Grid>
  )
}

export default Experience
