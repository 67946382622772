import DimaTourImg from '../../assets/dimatour.jpeg'
import HygearImg from '../../assets/hygear.jpeg'
import IndofundImg from '../../assets/indofund.jpeg'
import KaraImg from '../../assets/kara.jpeg'
import NobiImg from '../../assets/nobi.jpeg'
import SagaraImg from '../../assets/sagara.jpeg'
import SnapTogetherImg from '../../assets/snaptogether.jpeg'
import TerampilImg from '../../assets/terampil.jpeg'

const ExperienceData = [
  {
    title: 'Dima Tour & Travel',
    image: DimaTourImg,
    desc: 'Dima tour is a Company that handle Tour & Travel for Umrah & Hajj. I build this Company Profile Website using Serverless React & Firebase Technology',
    stack: 'React, Firebase',
    link: 'https://dima-tour-travel.web.app/'
  },
  {
    title: 'Kara Digital',
    image: KaraImg,
    desc: 'Kara Digital is a Software House, I working here and handle several project as Senior Backend Engineer.',
    stack: 'React, NodeJS, ExpressJS, MongoDB, BigQuery, AWS, Cloudinary, Golang',
    link: 'https://karadigital.co'
  },
  {
    title: 'Hygear',
    image: HygearImg,
    desc: 'The project is a part of Kara. Kara take responsibility to handle the project from our client. Hygear is Workout/Fitness Super App. They connect real device into the Apps. This App have World Wide Customers/Users.',
    stack: 'React, React Natice, NodeJS, ExpressJS,, MongoDB, AWS, BigQuery',
    link: 'https://hygearfit.com'
  },
  {
    title: 'Snap Together',
    image: SnapTogetherImg,
    desc: 'The project is a part of Kara. We build app that can bring the event into the next step. Just invite them into your event and you can snap as much as you can. And bring other member photo into your phone with just one tap.',
    stack: 'React, React Natice, NodeJS, ExpressJS, Python, Django, PostgreSQL, AWS',
    link: 'https://snaptogether.io'
  },
  {
    title: 'Sagara',
    image: SagaraImg,
    desc: 'Sagara is a Software House, I working here 2 months as Fullstack Freelancer. The project is about Web Data Statistic in Indonesia.',
    stack: 'NextJS, MySQL',
    link: 'https://sagaratechnology.com'
  },
  {
    title: 'Nobi',
    image: NobiImg,
    desc: 'Nobi is a management platform for asset crypto. I handle product called Earn Stacking.',
    stack: 'React, React Native, NodeJS, Express, Golang, MySQL, Jenkins, AWS',
    link: 'https://usenobi.com'
  },
  {
    title: 'Indofund',
    image: IndofundImg,
    desc: 'Indofund is a peer-to-peer lending portal that bridges fund recipients or business actors who need funds with Funders who want to invest in Funding. We are a company that wants to reach, know, and accelerate every line of business, whether large, medium, or small.',
    stack: 'React, Flutter, NodeJS, Express, MongoDB, AWS',
    link: 'https://indofund.id'
  },
  {
    title: 'Terampil',
    image: TerampilImg,
    desc: 'Terampis is EduTech Company. They serve E-Learning from Video for everyone who wants to grow.',
    stack: 'React, NodeJS, Express, PostgreSQL, AWS',
    link: 'https://terampil.com'
  }
]

export default ExperienceData
